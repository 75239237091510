import React from 'react';

const Footer = () => {
    return (
        <div className="lg:mt-28 mt-10 mb-7 px-12 border-t pt-7">
            <div
                className="flex flex-col items-center justify-between lg:flex-row max-w-6xl mx-auto lg:space-y-0 space-y-3">
                <p className="capitalize font-medium"> © copyright 2023 HCS Engineering</p>
                <div className="lg:flex space-x-4 text-gray-700 capitalize hidden">
                    <a href="#"> About</a>
                    <a href="#/privacy-policy"> Privacy</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;
