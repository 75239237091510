import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { getAllBook } from "../../api/app";
import Footer from "../../components/layout/Footer";
import { truncate } from "../../utils/trunate";
import { encodeQueryParameter } from "../../utils/url";
import { useSelector } from "react-redux";
import SEO from "../../components/layout/Seo";

const Books = () => {
  const auth = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [books, setBooks] = useState(null);

  useEffect(() => {
    getAllBook().then((response) => {
      if (!response.error) {
        setBooks(response.payload);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div>
      <SEO
        title="ZStudy | Books"
        description="Check out the varity of books available"
      />
      {loading ? (
        <div className="h-screen flex item-center justify-center">
          <div className="loader">
            <BeatLoader color="#003074" size={20} />
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="md:flex justify-between items-center mb-8">
            <div>
              <div className="text-xl font-semibold">Books</div>
              <div className="text-sm mt-2 font-medium text-gray-500 leading-6">
                {" "}
                Choose from {books.length} stem kits with free delivery country
                wide.
              </div>
            </div>
          </div>

          <div className="tube-card mt-3 lg:mx-0 -mx-5">
            <h4 className="py-3 px-5 border-b font-semibold text-grey-700">
              <ion-icon name="star"></ion-icon> Books
            </h4>

            <div className="divide-y">
              {books.map((item) => {
                return (
                  <div className="flex md:space-x-6 space-x-3 md:p-5 p-2 relative">
                    <a
                      href={`#/book/${item._id}`}
                      className="relative overflow-hidden bg-white shadow-sm md:rounded-lg rounded-md"
                    >
                      <img
                        src={item.cover_uri}
                        alt=""
                        className="w-full h-40 object-cover"
                      />
                    </a>
                    <div className="flex-1 md:space-y-2 space-y-1">
                      <a
                        href={`#/book/${item._id}`}
                        className="md:text-xl font-semibold line-clamp-2"
                      >
                        {" "}
                        {truncate(item.name, 30)}{" "}
                      </a>
                      <p className="leading-6 pr-4 line-clamp-2 md:block hidden">
                        {" "}
                        {truncate(item.description, 350)}{" "}
                      </p>
                      <a
                        href={`#/book/${item._id}`}
                        className="md:font-semibold block text-sm"
                      >
                        {" "}
                        K{item.amount}
                      </a>
                      <div className="flex items-center justify-between">
                        <div className="text-xs font-semibold uppercase text-yellow-500">
                          Online E-Book
                        </div>
                        <a
                          href={`#/book/${item._id}`}
                          className="md:flex items-center justify-center h-9 px-8 rounded-md border -mt-3.5 hidden"
                        >
                          {" "}
                          View{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Books;
