import React from 'react';
import { Route } from 'react-router-dom';
import Header from "../layout/Header";
import SideBar from "../layout/SideBar";

const PublicRoute = ({component: Component, ...rest}) => {
    return(
        <Route
            {...rest}
            render={props => (
                <div id="wrapper" className="horizontal">
                    <Header/>
                    <Component {...props} />
                </div>
            )
            }
        />
    )
};

export default PublicRoute;
