import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'parsleyjs';
import $ from 'jquery';
import { loginUser } from '../../api/auth';
import {Link} from "react-router-dom";

const Login = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        $('#login').parsley();

        return () => {
            $('#login').parsley("destroy");
        }
    }, []);

    //Selector
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [stateReady, setStateReady] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        setStateReady(true);
        dispatch(loginUser($('#email').val(), $('#password').val())).then(() => {
            setStateReady(false);
        });
    };

    if(isAuthenticated) {
        props.history.push('/');
    }

    return (
        <div id="wrapper" className="flex flex-col justify-between h-screen">
            <div className="bg-white py-4 shadow dark:bg-gray-800">
                <div className="max-w-6xl mx-auto">
                    <div className="flex items-center lg:justify-between justify-around">
                        <Link to="/" className="flex font-semibold items-center lg:text-2xl text-xl text-black">
                            <span className="logo-name">ZStudy</span>
                        </Link>
                        <div className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                            <Link to="login" className="py-3 px-4">Login</Link>
                            <Link to="register" className="bg-blue-500 blue-500 px-6 py-3 rounded-md shadow text-white">Register</Link>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
                    <form className="parsley-style-1 lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" autoComplete="off" id="login" data-parsley-validate="true" onSubmit={handleSubmit.bind()}>
                        <h1 className="lg:text-2xl text-xl font-semibold mb-6"> Login</h1>
                        <div id="emailWrapper" className="parsley-input">
                            <label className="mb-0" htmlFor="email"> Email Address </label>
                            <input type="email" id="email" placeholder="info@example.com" className="parsley-input h-12 mt-2 px-3 rounded-md w-full" data-parsley-class-handler="#emailWrapper" required/>
                    </div>
                        <div id="passwordWrapper" className="parsley-input">
                            <label className="mb-0" htmlFor="password"> Password </label>
                            <input type="password" id="password" autoComplete = "new-password" placeholder="******" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" data-parsley-class-handler="#passwordWrapper" required/>
                        </div>

                        <div>
                            {stateReady ? <button className="bg-blue-600 font-semibold p-2.5 mt-5 rounded-md text-center text-white w-full" disabled>Please Wait</button> : <button className="bg-blue-600 font-semibold p-2.5 mt-5 rounded-md text-center text-white w-full">Login with your account</button>}
                        </div>

                        <div className="uk-heading-line uk-text-center py-5"><span> Or, register with ZStudy </span></div>

                        <a href="#/register" className="relative lg:flex items-center justify-start w-full py-3 mt-2 overflow-hidden text-lg font-medium text-white bg-green-700 rounded-lg cursor-pointer">
                            <span className="inline-block text-base text-center text-white w-full">Signup</span>
                        </a>

                    </form>
                </div>

            </div>

            <div className="lg:mb-5 py-3 uk-link-reset">
                <div className="items-center uk-text-center max-w-6xl mx-auto lg:space-y-0 space-y-3">
                    <p className="capitalize"> © copyright {new Date().getFullYear()} by HCS Engineering</p>
                </div>
            </div>
        </div>
    )
};

export default Login;
