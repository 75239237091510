import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { getBook } from "../../api/app";
import Footer from "../../components/layout/Footer";
import moment from "moment";
import { encodeQueryParameter } from "../../utils/url";
import { useSelector } from "react-redux";
import SEO from "../../components/layout/Seo";

const BookDesc = (props) => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [book, setBook] = useState(null);

  const token = localStorage.getItem("jwtToken");
  const id = props.match.params.id;

  useEffect(() => {
    getBook(id, token).then((response) => {
      if (!response.error) {
        setBook(response.book);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div>
      <SEO title={book?.name} description={book?.description} />

      {loading ? (
        <div className="h-screen flex item-center justify-center">
          <div className="loader">
            <BeatLoader color="#003074" size={20} />
          </div>
        </div>
      ) : (
        <div className="max-w-5xl md:p-5 mx-auto">
          <div className="lg:flex lg:space-x-10 bg-white rounded-md shadow max-w-3x  mx-auto md:p-8 p-3">
            <div className="lg:w-1/3 w-full">
              <div
                className="md:block flex space-x-4"
                uk-sticky="offset: 91;bottom: true"
              >
                <div uk-lightbox>
                  <a href={book.uri} data-caption="Caption 1">
                    <img
                      alt=""
                      src={book.cover_uri}
                      className="shadow-lg rounded-md w-32 md:w-full"
                    />
                  </a>
                  <a href={book.uri} data-caption="Caption 2">
                    {" "}
                  </a>
                  <a href={book.uri} data-caption="Caption 3">
                    {" "}
                  </a>
                </div>
                <div className="flex-1">
                  <ul className="my-5 text-sm space-y-2">
                    <li> Price: K{book.amount}</li>
                    <li>
                      {" "}
                      Publish time {moment(book.release).format("Do MMM, YYYY")}
                    </li>
                  </ul>
                  <div className="grid grid-cols-2 gap-2">
                    {book.status !== null ? (
                      <a
                        href={book.uri}
                        target="_blank"
                        className="hover:text-gray-800 bg-gray-300 font-semibold inline-flex items-center justify-center px-4 py-2 rounded-md text-center w-full"
                      >
                        <i className="uil-book-open mr-1 md:block hidden"></i>{" "}
                        Read{" "}
                      </a>
                    ) : (
                      <a
                        href={
                          auth.isAuthenticated
                            ? `#/purchase/${encodeQueryParameter(
                                Object.assign(book, { type: "book" })
                              )}`
                            : `#/login`
                        }
                        className="bg-gray-600 font-semibold hover:bg-gray-700 inline-flex items-center justify-center px-4 py-2 rounded-md text-center text-white w-full"
                      >
                        <i className="uil-shopping-basket mr-1 md:block hidden"></i>{" "}
                        Buy{" "}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-2/3 flex-shrink-0 mt-10 lg:m-0">
              <div>
                <h2 className="font-semibold mb-3 text-xl lg:text-3xl">
                  {book.name}
                </h2>
                <hr className="mb-5" />
                <h4 className="font-semibold mb-2 text-base"> Description </h4>
                <div className="space-y-2">
                  <p>{book.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default BookDesc;
