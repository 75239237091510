import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { getCategories } from "../../api/app";
import Footer from "../../components/layout/Footer";
import SEO from "../../components/layout/Seo";

const Category = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    getCategories().then((response) => {
      if (!response.error) {
        setCategories(response.payload);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div>
      <SEO title="ZStudy | Category" description="some category description" />

      {loading ? (
        <div className="h-screen flex item-center justify-center">
          <div className="loader">
            <BeatLoader color="#003074" size={20} />
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="text-2xl font-semibold mb-3 text-black">
            {" "}
            Browse categories
          </div>

          <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4 mt-3">
            {categories.map((item) => {
              return (
                <a
                  href={`#/course-list/${item._id}`}
                  className="rounded-md overflow-hidden relative w-full lg:h-48 h-40"
                >
                  <div className="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10"></div>
                  <img
                    src={
                      item.cover_uri
                        ? item.cover_uri
                        : "https://i.imgur.com/gVvoCWG.png"
                    }
                    className="absolute w-full h-full object-cover"
                    alt=""
                  />
                  <div className="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">
                    {" "}
                    {item.name}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Category;
