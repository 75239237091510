import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'parsleyjs';
import $ from 'jquery';
import {signUp, verifyUserAccount} from '../../api/auth';
import {Link} from "react-router-dom";
import cogoToast from "cogo-toast";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {SET_AUTH_USER} from "../../stores/auth";

const Register = (props) => {
    const dispatch = useDispatch();

    //Selector
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [stateReady, setStateReady] = useState(false);

    useEffect(() => {
        $('#register').parsley();

        return () => {
            $('#register').parsley("destroy");
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        setStateReady(true);

        let email = $("#email").val();

        const payload = {
            firstName: $("#firstName").val(),
            lastName: $("#lastName").val(),
            mobileNumber: $("#mobileNumber").val(),
            email: email,
            password: $("#password").val()
        };

        verifyUserAccount(email).then((response) => {
            if(response.error) {
                setStateReady(false);
                cogoToast.error(response.payload, {position: "top-center", hideAfter: 5});
            }

            if(response.payload) {
                setStateReady(false);
                cogoToast.error("Email already exist. Use different one.", {position: "top-center", hideAfter: 5});
            } else {
                signUp(payload).then((response) => {
                    if(response.error) {
                        setStateReady(false);
                        cogoToast.error(response.payload, {position: "top-center", hideAfter: 5});
                        return false;
                    }

                    //Set token to localStorage
                    localStorage.setItem('jwtToken', response.payload);
                    //Set token to Auth header
                    setAuthToken(response.payload);
                    //Decode token to get user data
                    const decoded = jwt_decode(response.payload);
                    //Set current user
                    dispatch(SET_AUTH_USER({user: decoded, isAuthenticated: true}));
                });
            }
        });
    };

    if(isAuthenticated) {
        props.history.push('/');
    }

    return (
        <div id="wrapper" className="flex flex-col justify-between h-screen">
            <div className="bg-white py-4 shadow dark:bg-gray-800">
                <div className="max-w-6xl mx-auto">
                    <div className="flex items-center lg:justify-between justify-around">
                        <Link to="/" className="flex font-semibold items-center lg:text-2xl text-xl text-black">
                            <span className="logo-name">ZStudy</span>
                        </Link>
                        <div
                            className="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                            <Link to="/login" className="py-3 px-4">Login</Link>
                            <Link to="#" className="bg-blue-500 blue-500 px-6 py-3 rounded-md shadow text-white">Register</Link>
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
                    <form id="register" className="parsley-style-1 lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" autoComplete="off" data-parsley-validate="true" onSubmit={handleSubmit.bind()}>
                        <h1 className="lg:text-2xl text-xl font-semibold mb-6"> Register</h1>

                        <div className="grid lg:grid-cols-2 gap-3">
                            <div id="fNameWrapper" className="parsley-input">
                                <label className="mb-0" htmlFor="first-name"> First Name </label>
                                <input type="text" placeholder="Your First Name" id="firstName" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" data-parsley-class-handler="#fNameWrapper" required/>
                            </div>
                            <div id="lNameWrapper" className="parsley-input">
                                <label className="mb-0" htmlFor="last-name"> Last Name </label>
                                <input type="text" placeholder="Your Last Name" id="lastName" className="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" data-parsley-class-handler="#lNameWrapper" required/>
                            </div>
                        </div>

                        <div id="mobileWrapper" className="parsley-input">
                            <label className="mb-0" htmlFor="email"> Mobile Number </label>
                            <input type="number" id="mobileNumber" placeholder="Enter your mobile number" className="parsley-input h-12 mt-2 px-3 rounded-md w-full" data-parsley-class-handler="#mobileWrapper" required/>
                        </div>

                        <div id="emailWrapper" className="parsley-input">
                            <label className="mb-0" htmlFor="email"> Email Address </label>
                            <input type="email" id="email" placeholder="info@example.com" className="parsley-input h-12 mt-2 px-3 rounded-md w-full" data-parsley-class-handler="#emailWrapper" required/>
                        </div>

                        <div id="passwordWrapper" className="parsley-input">
                            <label className="mb-0" htmlFor="password"> Password </label>
                            <input type="password" id="password" autoComplete = "new-password" placeholder="******" className="parsley-input bg-gray-100 h-12 mt-2 px-3 rounded-md w-full" data-parsley-class-handler="#passwordWrapper" required/>
                        </div>

                        <div>
                            {stateReady ? <button className="bg-blue-600 font-semibold p-2.5 mt-5 rounded-md text-center text-white w-full" disabled>Please Wait</button> : <button className="bg-blue-600 font-semibold p-2.5 mt-5 rounded-md text-center text-white w-full">Register Now</button>}
                        </div>

                        <div className="uk-heading-line uk-text-center py-5"><span> Or, login with ZStudy</span></div>

                        <a href="#/login" className="relative lg:flex items-center justify-start w-full py-3 mt-2 overflow-hidden text-lg font-medium text-white bg-green-700 rounded-lg cursor-pointer">
                            <span className="inline-block text-base text-center text-white w-full">SignIn</span>
                        </a>
                    </form>
                </div>

            </div>

            <div className="lg:mb-5 py-3 uk-link-reset">
                <div className="items-center uk-text-center max-w-6xl mx-auto lg:space-y-0 space-y-3">
                    <p className="capitalize"> © copyright 2023 by HCS Engineering</p>
                </div>
            </div>
        </div>
    )
};

export default Register;
