import axios from "axios";
import {
    GET_COURSE_LIST_URL,
    GET_COURSES_URL,
    GET_USER_SUBSCRIPTION_URL,
    GET_USER_ORDER_URL,
    ASK_QUESTION,
    GET_PACKAGE_URL,
    COURSE_SUBSCRIBED_URL,
    BOOK_SUBSCRIBED_URL,
    ALL_CATEGORIES_URL,
    GET_ALL_ACADEMIA_URL,
    GET_COURSE_URL,
    GET_PAYMENT_METHOD_URL,
    UPDATE_NEW_PASSWORD_URL,
    UPDATE_ACCOUNT_URL,
    GET_CATEGORY_URL,
    GET_ALL_COURSES_URL,
    GET_PRODUCTS_URL,
    GET_PRODUCT_URL,
    GET_BOOKS_URL,
    GET_BOOK_URL,
    GET_ALL_PRODUCTS_URL,
    GET_ALL_BOOKS_URL
} from '../api';
import errorHandler from "../utils/errorHandler";
import {PACKAGES, PAYMENTS, COURSES, CATEGORY, BOOKS, PRODUCTS} from "../stores/app";

const token = localStorage.getItem('jwtToken');

export const getGlobal = () => {
    return function(dispatch) {
        return axios.all([
            axios.get(GET_PACKAGE_URL),
            axios.get(GET_PAYMENT_METHOD_URL),
            axios.post(GET_COURSES_URL, {page_size: 0}),
            axios.post(GET_CATEGORY_URL, {page_size: 0}),
            axios.post(GET_BOOKS_URL, {page_size: 0}),
            axios.post(GET_PRODUCTS_URL, {page_size: 0})
        ]).then(axios.spread(async(response1, response2, response3, response4, response5, response6) => {
            // Multiple requests are now complete
            await dispatch(PACKAGES({packages: response1.data.payload}));
            await dispatch(PAYMENTS({payments: response2.data.payload}));
            await dispatch(COURSES({courses: response3.data.payload}));
            await dispatch(CATEGORY({category: response4.data.payload}));
            await dispatch(BOOKS({books: response5.data.payload}));
            await dispatch(PRODUCTS({products: response6.data.payload}));

            return {
                error: false
            }
        })).catch((error) => {
            errorHandler(error, "top-right");
            return {
                payload: null,
                error: true
            }
        });
    }
};

export const getCourse = (id, auth) => {
    if(!auth) {
        return axios.get(GET_COURSE_URL(id)).then((response) => {
            return {
                error: false,
                course: response.data.payload
            }
        }).catch((error) => {
            errorHandler(error, "top-right");
            return {
                payload: null,
                error: true
            }
        });
    }

    const token = localStorage.getItem('jwtToken');
    console.log(token);
    return axios.get(COURSE_SUBSCRIBED_URL(id), {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            course: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};


export const getBook = (id, auth) => {
    if(!auth) {
        return axios.get(GET_BOOK_URL(id)).then((response) => {
            return {
                error: false,
                book: response.data.payload
            }
        }).catch((error) => {
            errorHandler(error, "top-right");
            return {
                payload: null,
                error: true
            }
        });
    }

    return axios.get(BOOK_SUBSCRIBED_URL(id), {headers: {"Authorization": auth}}).then((response) => {
        return {
            error: false,
            book: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getProduct = (id) => {
    return axios.get(GET_PRODUCT_URL(id)).then((response) => {
        return {
            error: false,
            book: response.data.payload
        }
    }).catch((error) => {
        errorHandler(error, "top-right");
        return {
            payload: null,
            error: true
        }
    });
};

export const getUserSubscription = () => {
    const auth = localStorage.getItem('jwtToken');
    return axios.get(GET_USER_SUBSCRIPTION_URL, {headers: {"Authorization": auth}}).then((response) => {
        return {
            error: false,
            subscriptions: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
};

export const getUserOrder = () => {
    const auth = localStorage.getItem('jwtToken');
    return axios.get(GET_USER_ORDER_URL, {headers: {"Authorization": auth}}).then((response) => {
        return {
            error: false,
            orders: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
};

export const getCourseList = (id) => {
    return axios.get(GET_COURSE_LIST_URL(id)).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
};

export const getAllCourse = () => {
    return axios.get(GET_ALL_COURSES_URL).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
}

export const getAllAcademia = () => {
    return axios.get(GET_ALL_ACADEMIA_URL).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
}

export const getAllProduct = () => {
    return axios.get(GET_ALL_PRODUCTS_URL).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
}

export const getAllBook = () => {
    return axios.get(GET_ALL_BOOKS_URL).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
}

export const askQuestion = (question) => {
    return axios.post(ASK_QUESTION, {question}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
};

export const getCategories = () => {
    return axios.get(ALL_CATEGORIES_URL).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    });
}

export const updatePassword = (cpassword, npassword) => {
    return axios.post(UPDATE_NEW_PASSWORD_URL, {cpassword, npassword}, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    })
};

export const updateAccount = (raw) => {
    return axios.post(UPDATE_ACCOUNT_URL, raw, {headers: {"Authorization": token}}).then((response) => {
        return {
            error: false,
            payload: response.data.payload
        }
    }).catch((error) => {
        return errorHandler(error);
    })
}
