// const API_URL = "http://127.0.0.1:6002/connect";
// const API_URL = process.env.REACT_APP_API_URL;
const API_URL = 'https://api.zstudy.co/connect';
// const API_URL = 'https://server.test.zstudy.co/connect';

exports.SIGNIN_URL = `${API_URL}/sign-in`;
exports.SIGNUP_URL = `${API_URL}/sign-up`;
exports.VERIFY_USER_URL = `${API_URL}/verify-user`;
exports.SEND_CODE_URL = `${API_URL}/send-code`;
exports.VERIFY_CODE_URL = `${API_URL}/verify-otp`;
exports.GET_ALL_ACTIVITY_URL = `${API_URL}/get-activity`;
exports.GET_ALL_CATEGORIES_URL = `${API_URL}/get-categories`;
exports.GET_ALL_COURSES_URL = `${API_URL}/get-courses`;
exports.GET_ALL_ACADEMIA_URL = `${API_URL}/academia`;
exports.GET_ALL_PRODUCTS_URL = `${API_URL}/get-products`;
exports.GET_ALL_BOOKS_URL = `${API_URL}/get-books`;
exports.ALL_CATEGORIES_URL = `${API_URL}/categories`;
exports.GET_COURSES_URL = `${API_URL}/courses`;
exports.GET_BOOKS_URL = `${API_URL}/books`;
exports.GET_PRODUCTS_URL = `${API_URL}/products`;
exports.GET_COURSE_LIST_URL = (id) => `${API_URL}/course-list/${id}`;
exports.GET_CATEGORY_URL = `${API_URL}/category`;
exports.ASK_QUESTION = `${API_URL}/question-ai`;
exports.USER_VIEWED_URL = `${API_URL}/user-viewed`;
exports.GET_ALL_COURSES_BY_ID_URL = (id) =>
  `${API_URL}/get-courses-by-id/${id}`;
exports.COURSE_BY_CATEGORY = `${API_URL}/courses-by-category`;
exports.GET_USER_SUBSCRIBED_URL = `${API_URL}/user-subscribed`;
exports.UPDATE_NEW_PASSWORD_URL = `${API_URL}/update-new-password`;
exports.UPDATE_ACCOUNT_URL = `${API_URL}/update-account`;
exports.SEARCH_COURSE_URL = `${API_URL}/search-course`;
exports.GET_USER_SUBSCRIPTION_URL = `${API_URL}/user-subscription`;
exports.GET_USER_ORDER_URL = `${API_URL}/user-order`;
exports.GET_SUBSCRIBED_COURSES_URL = `${API_URL}/subscribed-courses`;
exports.VERIFY_MOBILE_TOKEN_URL = `${API_URL}/verify-token-payment`;
exports.GET_PAYMENT_METHOD_URL = `${API_URL}/get-payment-method`;
exports.SUBSCRIBED_COURSE_URL = (id) => `${API_URL}/subscribed-course/${id}`;
exports.COURSE_SUBSCRIBED_URL = (id) => `${API_URL}/course-subscribed/${id}`;
exports.BOOK_SUBSCRIBED_URL = (id) => `${API_URL}/book-subscribed/${id}`;
exports.GET_COURSE_URL = (id) => `${API_URL}/course/${id}`;
exports.GET_BOOK_URL = (id) => `${API_URL}/book/${id}`;
exports.GET_PRODUCT_URL = (id) => `${API_URL}/product/${id}`;
exports.DEBIT_PAYMENT_URL = `${API_URL}/debit-payment`;
exports.GET_PACKAGE_URL = `${API_URL}/get-package`;
exports.CHARGE_MOBILE_MONEY_TOKEN_URL = `${API_URL}/charge-mobile-money-token`;
exports.PRODUCT_CHARGE_MOBILE_MONEY_TOKEN_URL = `${API_URL}/product-charge-mobile-money-token`;
exports.BOOK_CHARGE_MOBILE_MONEY_TOKEN_URL = `${API_URL}/book-charge-mobile-money-token`;
exports.RETRY_PAYMENT_URL = `${API_URL}/retry-payment`;
exports.VERIFY_TOKEN_PAYMENT_URL = `${API_URL}/verify-token-payment`;
exports.CHARGE_DEBIT_CARD_TOKEN_URL = `${API_URL}/charge-debit-credit-card`;
exports.PRODUCT_CHARGE_DEBIT_CARD_TOKEN_URL = `${API_URL}/product-charge-debit-credit-card`;
exports.UPDATE_RECOVERY_PASSWORD_URL = `${API_URL}/update-recovery-password`;
