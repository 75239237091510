import React, { useState, useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { getAllAcademia } from "../../api/app";
import Footer from "../../components/layout/Footer";
import { truncate } from "../../utils/trunate";
import { encodeQueryParameter } from "../../utils/url";
import { useSelector } from "react-redux";
import useAnalyticsEventTracker from "../../utils/ga";
import SEO from "../../components/layout/Seo";

const Academia = () => {
  const auth = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [academia, setAcademia] = useState(null);

  useEffect(() => {
    getAllAcademia().then((response) => {
      if (!response.error) {
        setAcademia(response.payload);
        setLoading(false);
        gaEventTracker("call");
      }
    });
  }, []);

  const gaEventTracker = useAnalyticsEventTracker("Government Program");

  return (
    <div>
      <SEO title={academia?.name} description={academia?.role} />
      {loading ? (
        <div className="h-screen flex item-center justify-center">
          <div className="loader">
            <BeatLoader color="#003074" size={20} />
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="md:flex justify-between items-center mb-8">
            <div>
              <div className="text-xl font-semibold">Schools</div>
              <div className="text-sm mt-2 font-medium text-gray-500 leading-6">
                {" "}
                Choose from {academia.length} schools with new course additions
                published.
              </div>
            </div>
          </div>

          <div className="tube-card mt-3 lg:mx-0 -mx-5">
            <h4 className="py-3 px-5 border-b font-semibold text-grey-700">
              <ion-icon name="star"></ion-icon> Schools
            </h4>

            <div className="divide-y">
              {academia.map((item) => {
                console.log(academia);
                return (
                  <div className="flex md:space-x-6 space-x-3 md:p-5 p-2 relative">
                    <a
                      href={`https://myschool.zstudy.co/#/school/${item.id_number}`}
                      className="md:w-20 md:h-20 w-20 h-20 overflow-hidden align-self-center rounded-lg relative"
                    >
                      <img
                        src={item.logo.uri}
                        alt=""
                        className="w-full h-full img-fit-cover"
                      />
                    </a>
                    <div className="flex-1 md:space-y-2 space-y-1">
                      <a
                        href={`https://myschool.zstudy.co/#/school/${item.id_number}`}
                        className="md:text-xl font-semibold line-clamp-2"
                      >
                        {" "}
                        {truncate(item.name, 30)}{" "}
                      </a>
                      <a
                        href={`https://myschool.zstudy.co/#/school/${item.id_number}`}
                        className="md:font-semibold block text-sm"
                      >
                        {" "}
                        {item.id_number}
                      </a>
                      <div className="flex items-center justify-between">
                        <div className="flex space-x-2 items-center text-sm">
                          <div> Address</div>
                          <div className="md:block hidden">·</div>
                          <div className="flex items-center">
                            {" "}
                            {truncate(item.address, 130)}
                          </div>
                        </div>
                        <a
                          href={`https://myschool.zstudy.co/#/school/${item.id_number}`}
                          className="md:flex items-center justify-center h-9 px-8 rounded-md border -mt-3.5 hidden"
                        >
                          {" "}
                          Login to Portal{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Academia;
