import React from 'react';
import {decodeQueryParameter} from "../../utils/url";

const Interactive = (props) => {

    const simulator = decodeQueryParameter(props.match.params.simulator);

    return (
        <div className="container">

            <h1 className="text-2xl font-bold text-gray-600 leading-0 my-3">{simulator.title} - Interactive Learning </h1>

            <div className="tube-card mt-3 p-5 lg:mx-0 -mx-5 mb-5">
                <h2 className="text-2xl font-bold text-gray-500 leading-0 mb-3">1. Description</h2>
                <p>{simulator.description}</p>
                <hr className="mt-5"/>

                <h2 className="text-2xl font-bold text-gray-500 leading-0 mt-5 mb-3">2. Instruction</h2>
                <div dangerouslySetInnerHTML={{__html: atob(simulator.instruction) }}/>

                <a href={simulator.file.uri} target="_blank" className="bg-blue-600 py-3 mt-5 font-semibold p-2.5 rounded-md text-center text-white w-full" type="submit">Start Learning</a>
            </div>

            {/*<nav className="cd-secondary-nav border-b md:m-0 -mx-4 nav-large">*/}
            {/*    <ul uk-switcher="connect: #elements-nav ;animation: uk-animation-fade ; toggle: > * ">*/}
            {/*        <li className="uk-active" aria-expanded="true"><a href="#" className="lg:px-2">Description</a></li>*/}
            {/*        <li aria-expanded="false" className=""><a href="#" className="lg:px-2">Instruction</a></li>*/}
            {/*        <li aria-expanded="false" className=""><a href="#" className="lg:px-2">Simulator</a></li>*/}
            {/*    </ul>*/}
            {/*</nav>*/}

            {/*<div className="uk-switcher my-12" id="elements-nav">*/}
            {/*    <div className="uk-active">*/}
            {/*        <div className="tube-card mt-3 p-5 lg:mx-0 -mx-5">*/}
            {/*            <p>{simulator.description}</p>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*        <div className="tube-card mt-3 p-5 lg:mx-0 -mx-5">*/}
            {/*            <div dangerouslySetInnerHTML={{__html: atob(simulator.instruction) }}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*        <div className="tube-card mt-3 p-5 lg:mx-0 -mx-5">*/}
            {/*            <h1>Start Interactive Learning</h1>*/}
            {/*            <a href={simulator.file.uri} target="_blank" className="bg-blue-600 py-3 mt-5 font-semibold p-2.5 rounded-md text-center text-white w-full" type="submit">Start Learning</a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </div>
    );
};

export default Interactive;
