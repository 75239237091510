import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../api/auth";
import "simplebar";
import SimpleBar from "simplebar-react";
import formatNumber from "../../utils/formatNumber";
import {REMOVE_FROM_CART} from "../../stores/cart";
import {truncate} from "../../utils/trunate";

const Header = (prop) => {

    const auth = useSelector((state) => state.auth.user);
    const carts = useSelector((state) => state.cart.products);

    const dispatch = useDispatch();

    const [menu, set_menu] = useState({
        explore: "",
        courses: "",
        book: "",
        category: "",
        question: "",
        academia: ""
    });

    // //detect url change and update it.
    useEffect(() => {
        let url = window.location.hash.split("/").pop();
        Object.keys(menu).forEach(function(key){ set_menu((prevState) => ({
            ...prevState,
            [key]: "",
            [url]: "active"
        }))});
    }, [window.location.hash.split("/").pop()])

    const subtotal = () => {
        return carts.reduce(function (result, item) {
            return result + item.ori_price * item.quantity;
        }, 0);
    }

    const onLogoutClick = (event) => {
        event.preventDefault();
        dispatch(logoutUser());
    };

    const removeItemCart = (id) => {
        dispatch(REMOVE_FROM_CART({_id: id}))
    }

    return (
        <header uk-sticky="border-b">
            <div className="header_inner">
                <div className="left-side">

                    <div id="logo">
                        <Link to="/" className="flex font-semibold items-center lg:text-2xl text-xl text-black">
                            <span className="logo-name">ZStudy</span>
                        </Link>
                    </div>

                    <div className="triger" uk-toggle="target: .header_menu ; cls: is-visible"></div>

                    <nav className="header_menu">
                        <ul>
                            <li><a href="#" className={menu.explore}> Explore </a></li>
                            <li><a href="#/courses" className={menu.courses}> Courses </a></li>
                            <li><a href="#/category" className={menu.category}> Category </a></li>
                            <li><a href="#/books" className={menu.category}> Books </a></li>
                            <li><a href="#/products" className={menu.courses}> STEM Kits </a></li>
                            <li><a href="#/academia" className={menu.school}> School </a></li>
                            {auth.isAuthenticated ? <li><a href="#/question" className={menu.question}> Ask Question</a></li> : null}
                        </ul>
                    </nav>

                    <div className="overly" uk-toggle="target: .header_menu ; cls: is-visible"></div>
                </div>

                <div className="right-side">
                    {auth.isAuthenticated ? <>
                        <a href="#" className="header_widgets">
                            <ion-icon name="cart-outline" class="is-icon"></ion-icon>
                        </a>
                        <div uk-drop="mode: click" className="dropdown_cart">
                            <div className="cart-headline"> My Cart
                                <a href="#/cart" className="checkout">View All</a>
                            </div>

                            {carts.length > 0 ? <ul className="dropdown_cart_scrollbar">
                                {carts.map((item) => {
                                    return (
                                        <li>
                                            <div className="cart_avatar">
                                                <img src={item.img} alt="" className="object-contain"/>
                                            </div>
                                            <div className="cart_text">
                                                <h4> {truncate(item.name, 20)} </h4>
                                                <p>K{formatNumber(item.ori_price)} x {item.quantity}</p>
                                            </div>
                                            <div className="cart_price">
                                                <span> K{item.ori_price * item.quantity} </span>
                                                <button className="type"
                                                        onClick={removeItemCart.bind(null, item._id)}> Remove
                                                </button>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul> : <p className="text-center p-5">The cart is empty. You do not have any cart yet.</p>}

                            <div className="cart_footer">
                                <h2> Total Items : {carts.length}</h2>
                                <h2><strong>Total : K{subtotal()}</strong></h2>
                            </div>
                        </div>
                    </> : null }
                    {auth.isAuthenticated ?
                        <div>
                            <a href="#">
                                <img src="../assets/images/avatars/placeholder.png" className="header_widgets_avatar" alt=""/>
                            </a>
                            <div uk-drop="mode: click;offset:5" className="header_dropdown profile_dropdown">
                                <ul>
                                    <li>
                                        <a href="#" className="user">
                                            <div className="user_avatar">
                                                <img src="assets/images/avatars/placeholder.png" alt=""/>
                                            </div>
                                            <div className="user_name">
                                                <div> {auth.user.firstName ? `${auth.user.firstName} ${auth.user.lastName}` : "No Name"} </div>
                                                <span> {auth.user.email} </span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <hr/>
                                    </li>
                                    <li>
                                        <a href="#/order">
                                            <ion-icon name="bag-outline" class="is-icon"></ion-icon>
                                            Orders
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#/account">
                                            <ion-icon name="settings-outline" class="is-icon"></ion-icon>
                                            Account Settings
                                        </a>
                                    </li>
                                    <li>
                                        <hr/>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={onLogoutClick.bind()}>
                                            <ion-icon name="log-out-outline" class="is-icon"></ion-icon>
                                            Log Out
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div> : <div>
                            <Link to="/login" className="px-6 px-4">Login</Link>
                            <Link to="/register" className="bg-blue-500 blue-500 px-6 py-3 rounded-md shadow text-white">Register</Link>
                        </div>}
                </div>
            </div>
        </header>
    );
};

export default Header;
