import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {getAllProduct} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {truncate} from "../../utils/trunate";
import {useDispatch, useSelector} from "react-redux";
import cogoToast from "cogo-toast";
import {ADD_TO_CART} from "../../stores/cart";
import SEO from '../../components/layout/Seo';

const Products = (prop) => {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const carts = useSelector((state) => state.cart.products);

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        getAllProduct().then((response) => {
            if(!response.error) {
                setProducts(response.payload)
                setLoading(false);
            }
        })
    }, []);

    const onAddCart = (product) => {

        if(!auth.isAuthenticated) {
            prop.history.push("login");
        }

        let productItem = carts.find((result) => result._id === product._id);

        if(productItem !== undefined) {
            cogoToast.error("You already added this item to the cart basket.", {hideAfter: 3});
            return false;
        }

        dispatch(ADD_TO_CART({products: [
                {
                    "_id": product._id,
                    "name": product.name,
                    "img": product.image_uri,
                    "ori_price": product.amount,
                    "description": product.description,
                    "price": product.amount,
                    "units": 1,
                    "quantity": 1
                }
            ]}));

        cogoToast.success("Item has been added to the card.", {hideAfter: 3});
    };

    const statusCart = (data) => {
        const cart = carts.find((item) => {
            return item._id === data._id
        });

        return cart !== undefined ? "Added to Cart" : "Add to Cart";
    }

    return (
        <div>
            <SEO title="ZStudy | Products" description="Check our products" />
            {loading ? <div className="h-screen flex item-center justify-center">
                    <div className="loader">
                        <BeatLoader color="#003074" size={20} />
                    </div>
                </div> :
                <div className="container">
                    <div className="md:flex justify-between items-center mb-8">
                        <div>
                            <div className="text-xl font-semibold">STEM Kits</div>
                            <div className="text-sm mt-2 font-medium text-gray-500 leading-6"> Choose from STEM kits
                                with free delivery country wide.
                            </div>
                        </div>
                    </div>

                    <div className="tube-card mt-3 lg:mx-0 -mx-5">
                        <h4 className="py-3 px-5 border-b font-semibold text-grey-700">
                            <ion-icon name="star"></ion-icon> Products
                        </h4>

                        <div className="divide-y">
                            {products.map((item) => {
                                return(
                                    <div className="flex md:space-x-6 space-x-3 md:p-5 p-2 relative">
                                        <a href={`#/product/${item._id}`} className="md: w-60 h-40 overflow-hidden rounded-lg img-responsive relative shadow-sm">
                                            <img src={item.image_uri} alt="" className="w-full h-full absolute inset-0 object-contain"/>
                                        </a>
                                        <div className="flex-1 md:space-y-2 space-y-1">
                                            <a href={`#/product/${item._id}`} className="md:text-xl font-semibold line-clamp-2"> {truncate(item.name, 30)} </a>
                                            <p className="leading-6 pr-4 line-clamp-2 md:block hidden"> {truncate(item.description, 130)} </p>
                                            <a href={`#/product/${item._id}`} className="md:font-semibold block text-sm"> K{item.amount}</a>
                                            <div className="flex items-center justify-between">
                                                {item.in_stock ? <div className="text-xs font-semibold text-green-500">In-Stock</div> : <div className="text-xs font-semibold text-red-500">Out-Of-Stock</div>}
                                                {item.in_stock ? <a href="javascript:void(0)" onClick={onAddCart.bind(null, item)} className="md:flex items-center justify-center h-9 px-8 rounded-md border -mt-3.5 hidden"> {statusCart(item)} </a> : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
            <Footer/>
        </div>
    );
};

export default Products;
