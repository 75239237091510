import React, {useState, useEffect} from 'react';
import {BeatLoader} from "react-spinners";
import {getProduct} from "../../api/app";
import Footer from "../../components/layout/Footer";
import {useDispatch, useSelector} from "react-redux";
import {encodeQueryParameter} from "../../utils/url";
import cogoToast from "cogo-toast";
import {ADD_TO_CART} from "../../stores/cart";
import SEO from '../../components/layout/Seo';

const ProductDesc = (props) => {

    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState(null);

    const id = props.match.params.id;

    const carts = useSelector((state) => state.cart.products);

    useEffect(() => {
        getProduct(id).then((response) => {
            if(!response.error) {
                setProduct(response.book)
                setLoading(false);
            }
        });
    }, []);

    const onAddCart = (product) => {
        let productItem = carts.find((result) => result._id === product._id);

        if(productItem !== undefined) {
            cogoToast.error("You already added this item to the cart basket.", {hideAfter: 3});
            return false;
        }

        dispatch(ADD_TO_CART({products: [
                {
                    "_id": product._id,
                    "name": product.name,
                    "img": product.image_uri,
                    "ori_price": product.amount,
                    "description": product.description,
                    "price": product.amount,
                    "units": 1,
                    "quantity": 1
                }
            ]}));

        cogoToast.success("Item has been added to the card.", {hideAfter: 3});
    };

    return (
        <div>
            <SEO title={product?.title} description={product?.description} />
            {loading ? <div className="h-screen flex item-center justify-center">
                    <div className="loader">
                        <BeatLoader color="#003074" size={20} />
                    </div>
                </div> :
                <div className="max-w-3xl md:p-5 mx-auto">

                    <div className="card lg:mx-0 uk-animation-slide-bottom-small">
                        <div className="flex justify-between items-center lg:p-4 p-2.5">
                            <div className="flex flex-1 items-center space-x-4">
                                <a href="#">
                                    <img src="assets/images/cover.jpg" className="bg-gray-200 border border-white rounded-full w-10 h-10"/>
                                </a>
                                <div className="flex-1 font-semibold capitalize">
                                    <a href="#" className="text-black"> ZStudy for STEM </a>
                                </div>
                            </div>
                        </div>

                        <div uk-lightbox="">
                            <a href="assets/images/cover.jpg">
                                <img src={product.image_uri} alt="" className="max-h-96 w-full object-contain"/>
                            </a>
                        </div>


                        <div className="p-4 space-y-3 relative">
                            <div className="text-2xl font-semibold pt-2"> {product.name}</div>
                            <p> {product.description}</p>

                            <div
                                className="top-3 absolute bg-gray-100 font-semibold px-3 py-1 right-3 rounded-full text text-sm">
                                K{product.amount}
                            </div>

                            <div className="flex space-x-3 items-center text-sm md:pt-3">
                                {product.in_stock ? <div className="font-semibold text-green-500"> In-Stock</div> : <div className="font-semibold text-red-500"> Out-of-Stock</div>}
                            </div>

                            <hr/>

                            <div className="grid grid-cols-2 gap-4 mb-5">
                                <a href="tel:+260967754716" className="bg-gray-200 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md">
                                    Contact seller
                                </a>
                                {product.in_stock ? <button onClick={onAddCart.bind(null, product)} className="bg-blue-600 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md text-white">Add to Cart</button> : <button className="bg-red-600 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md text-white" disabled>Out of Stock</button>}
                            </div>
                        </div>

                    </div>
                </div>
            }
            <Footer/>
        </div>
    );
};

export default ProductDesc;
